import React, { useState } from "react";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { distance } from "fastest-levenshtein";
import clsx from "clsx";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { Section, Expandable, Accordion } from "../components/ui";
import SearchIcon from "../images/search-icon.inline.svg";
import ClearIcon from "../images/x-circle.inline.svg";
import CloseIcon from "../images/x.inline.svg";
import { groups } from "../mock/local-groups";

const scoreVector = (query, group) => {
  return [
    distance(query, group.name.toLowerCase().slice(0, query.length)),
    distance(query, group.postcode.slice(0, query.length)),
    distance(query, group.name.toLowerCase()),
    distance(query, group.postcode),
  ];
};

const scoreGroupByQuery = (query, group) => {
  return Math.min(...scoreVector(query, group));
};

const GroupsPage = ({ data, location }) => {
  const { t } = useTranslation("local-groups");
  const siteTitle = data?.site?.siteMetadata?.title || ``;

  const [query, setQuery] = useState("");
  const [result, setResult] = useState([]);

  const onQueryChange = event => {
    const value = event.target.value;
    setQuery(value);

    const searchTerm = value?.trim().toLowerCase();
    if (searchTerm && searchTerm.length > 0) {
      const result = groups.sort((a, b) => {
        return (
          scoreGroupByQuery(searchTerm, a) - scoreGroupByQuery(searchTerm, b)
        );
      });
      setResult(result);
    } else {
      const result = [];
      setResult(result);
    }
  };

  const clearQuery = () => {
    setQuery("");
    setResult([]);
  };

  const hasResult = result.length > 0;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={t("Finde Lokale Gruppen")} />

      <Section
        backgroundColor="helfa-beige"
        className={clsx({ "drop-shadow-lg": hasResult })}
      >
        <h2 className={clsx("text-2xl text-left", { "my-2": false })}>
          {t("Lokale Gruppen")}
        </h2>
        <div className={clsx({ hidden: hasResult })}>
          <h1 className="text-4xl font-semibold text-left my-3 leading-relaxed">
            {t("Finde Deine lokalen H.e.l.f.a.-Gruppen in Deiner Stadt")}
          </h1>
        </div>
        <form
          className={clsx("relative flex items-center")}
          onSubmit={() => false}
        >
          <SearchIcon
            stroke="currentColor"
            className="absolute left-3.5 h-6 text-gray-400"
          />
          {query && (
            <button
              className="inline-block absolute right-3.5"
              onClick={clearQuery}
            >
              <ClearIcon stroke="currentColor" className="h-6 text-gray-400" />
            </button>
          )}
          <input
            value={query}
            onChange={onQueryChange}
            className="my-2 bg-gray-200 rounded-full w-full text-lg focus:outline-none font-light pl-10 p-3"
            type="text"
            placeholder={t("PLZ oder Stadt")}
          ></input>
        </form>
      </Section>

      <Section backgroundColor="helfa-beige">
        <div className={clsx("text-left", { hidden: !hasResult })}>
          <div className="flex items-center justify-between pb-3 px-2">
            <h3 className="text-xl font-semibol">Suchvorschläge</h3>
            <button onClick={clearQuery}>
              <CloseIcon />
            </button>
          </div>
          {hasResult &&
            result.map(group => (
              <Link
                to={`/local-groups/${group.id}`}
                key={group.id}
                className="block"
              >
                <div className="p-2 hover:bg-helfa-beige-darker border-b border-helfa-beige-darker flex justify-between items-center">
                  <div>
                    <div className="font-semibold">{group.name}</div>
                    <div>{group.postcode}</div>
                  </div>
                  <div className="rounded-full bg-blue-600 text-white text-sm py-1 px-3">
                    {t("Lokale Gruppe")}
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </Section>

      <Section
        backgroundColor="helfa-beige"
        className={clsx({ hidden: hasResult })}
      >
        <Accordion>
          <Expandable title={t("Zweck")} expanded={true}>
            {t(
              "Lokale Ortsgruppen sind die Gruppen, die ganz am unteren Ende der Hierarchie sind. Sie können Städte, Stadtteile oder Straßen sein, je nachdem wie dicht das Netzwerk in der Gegend ist. Diese Gruppen dienen zum Eingang ins Helfa Netzwerk, denn nur wenn wir uns kennen, können wir Vertrauen aufbauen. Und Vertrauen ist das, was wir brauchen, um etwas zu verändern."
            )}
          </Expandable>
          <Expandable title={t(`Aufgaben`)}>
            <ul className="list-disc list-inside">
              <li>{t("Sich um die eigenen Mitglieder kümmern")}</li>
              <li>{"Sich mit den Mitglieder von anderen Gruppen vernetzen"}</li>
              <li>
                {t(
                  "Projekte auf die Beine zu stellen, um die Gruppe auch in Notsituationen aufrecht zu erhalten"
                )}
              </li>
              <li>
                {t(
                  "Immer mehr anbieten, bis das Geld nicht mehr so wichtig ist"
                )}
              </li>
              <li>{t("Spaßfaktor nicht vergessen")}</li>
              <li>{t("Eine Zukunft für sich und die Kinder aufbauen")}</li>
            </ul>
          </Expandable>
          <Expandable title={t(`Weiteres`)}>Weiteres...</Expandable>
        </Accordion>
      </Section>
    </Layout>
  );
};

export default GroupsPage;

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      ...SiteMetadata
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ...I18n
        }
      }
    }
  }
`;
