export const groups = [
  {
    id: 1,
    name: "Hamburg",
    receptionChannel: "https://t.me/joinchat/wfiOE4pxhdY0NzQy",
    type: "LOCAL",
    postcode: "20345",
  },
  {
    id: 2,
    name: "Köln",
    receptionChannel: "https://t.me/joinchat/wfiOE4pxhdY0NzQy",
    type: "LOCAL",
    postcode: "40234",
  },
  {
    id: 3,
    name: "Berlin",
    receptionChannel: "https://t.me/joinchat/wfiOE4pxhdY0NzQy",
    type: "LOCAL",
    postcode: "10823",
  },
  {
    id: 4,
    name: "Stuttgart",
    receptionChannel: "https://t.me/joinchat/wfiOE4pxhdY0NzQy",
    type: "LOCAL",
    postcode: "70234",
  },
  {
    id: 5,
    name: "München",
    receptionChannel: "https://t.me/joinchat/wfiOE4pxhdY0NzQy",
    type: "LOCAL",
    postcode: "80234",
  },
];
